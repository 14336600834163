import React, { useState, useEffect, useRef } from "react";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";
import moment from "../../helper/moment";
import { userAtom } from "../../state/auth";
import { socketAtom } from "../../state/socket";
import { getOldMessages } from "../../api/chat";
import io from "socket.io-client";
import { refreshAtomNotification } from "../../state/refreshStateNotifications";
import Button from "../ButtonUI";
import makeToast from "../Snackbar";

interface IProps {
  chatWithId: any;
}

const Chat: React.FC<IProps> = ({ chatWithId }) => {
  const history = useHistory();
  const [user] = useAtom<any>(userAtom);
  const [messages, setMessages] = useState<any>([]);
  const [refreshUnReadMsg, setRefreshUnReadMsg] = useAtom(
    refreshAtomNotification
  );
  const [displayMessage, setDisplayMessage] = useState<any>([]);
  const [socket, setSocket] = useAtom(socketAtom) as any;
  const messagesRef = useRef<any>();
  const blockMessageRef = useRef<any>();

  const chatroomId = chatWithId?._id;

  const scrollToBottom = () => {
    blockMessageRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  useEffect(() => {
    return () => {
      setRefreshUnReadMsg({ state: !refreshUnReadMsg?.state });
    };
  }, []);
  const KatomiEndPoint =
    process.env.REACT_APP_KATOMI_API_URL || "http://localhost:3009";

  console.log("user", user?.type);

  const setupSocket = () => {
    const newSocket = io(KatomiEndPoint, {
      query: { userId: user?._id },
    });
    newSocket.on("disconnect", () => {
      setSocket(null);
    });
    newSocket.on("connection", () => {
      makeToast("success", "Connected!");
    });

    setSocket(newSocket);
  };

  useEffect(() => {
    if (!socket) {
      setupSocket();
    }
  }, []);
  const sendMessage = () => {
    if (socket) {
      //console.log("chatroomId", demandeData?._id);
      if (user?.type == "medecin") {
        socket.emit("chatroomMessage", {
          sentFrom: user?._id,
          sentTo: chatWithId.docExpert,
          type: "text",
          message: messagesRef.current.value,
          chatroomId,
        });

        messagesRef.current.value = "";
      } else if (user?.type == "medecinTeleexpert") {
        socket.emit("chatroomMessage", {
          sentFrom: user?._id,
          sentTo: chatWithId.docReq,
          type: "text",
          message: messagesRef.current.value,
          chatroomId,
        });

        messagesRef.current.value = "";
      }
    }
  };
  //get All Old Messages
  useEffect(() => {
    async function _getOldMessage() {
      const response = await getOldMessages(chatroomId);
      setMessages(response);
    }
    _getOldMessage();
  }, [chatroomId]);

  // Group Message By Date
  useEffect(() => {
    if (messages && messages.length > 0) {
      const groups = messages.reduce((groups, el) => {
        const date = moment(el.createdAt).format("DD/MM/YYYY");
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(el);
        return groups;
      }, {});
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          data: groups[date],
        };
      });
      setDisplayMessage(groupArrays);
    }
  }, [messages]);

  // get RealTime New Messages
  useEffect(() => {
    if (socket) {
      socket.on("newMessage", (message) => {
        const newMessages = [...messages, message];
        setMessages(newMessages);
      });
    }
    //eslint-disable-next-line
  }, [messages]);

  //Socket Join Chat Room
  useEffect(() => {
    if (socket) {
      socket.emit("joinRoom", {
        chatroomId,
      });
    }
    return () => {
      //Component Unmount
      if (socket) {
        socket.emit("leaveRoom", {
          chatroomId,
        });
      }
    };
    //eslint-disable-next-line
  }, [socket]);

  // useEffect(() => scrollToBottom(), [messages, blockMessageRef]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    blockMessageRef.current?.scrollIntoView({});
  }, [messages, displayMessage, blockMessageRef]);

  return (
    <div className="chat-box w-full h-full m-2 flex flex-col pr-3 ">
      <div
        className="flex flex-col justify-end m-3 space-y-3 right-0 absolute bottom-0 mb-24 w-full"
        style={{ height: "60%" }}
        // ref={blockMessageRef}
      >
        {displayMessage &&
          displayMessage.length > 0 &&
          displayMessage.map((el, i) => (
            <div className="relative">
              <hr className="hr-center-text flex-1" data-content={el.date} />
              {el.data.map(({ from, date, message, ...rest }) => {
                return (
                  <div>
                    <div
                      className={`flex items-center space-x-2 my-2 mr-3  ${
                        rest?.sentFrom == user?._id && "flex-row-reverse "
                      }`}
                    >
                      {rest?.sentFrom != user?._id && (
                        <span className="ml-10"></span>
                      )}

                      <span
                        className={`message-chat max-w-max${
                          rest?.sentFrom == user?._id && "text-right p-2 you "
                        }`}
                      >
                        {message}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        <div ref={blockMessageRef}></div>
      </div>

      <div className="flex items-center w-full mb-4 absolute bottom-4 right-0 mr-3">
        <textarea
          //type="text"
          name="chat"
          className="input-chat-box w-full mx-2 pl-5 ml-8"
          style={{ height: "45px" }}
          placeholder="Tapez ici votre message..."
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     sendMessage();
          //   }
          // }}
          ref={messagesRef}
          rows={5}
          cols={50}
        />

        <Button className="p-2" onClick={sendMessage}>
          Envoyer
        </Button>
      </div>
      {/* <div style={{ float: "left", clear: "both" }} ref={blockMessageRef}></div> */}
    </div>
  );
};
export default Chat;
